import React from "react";

function Footer() {
    return (
        <div className="Footer">
            <p>© 2022 Maximilian Kuechen</p>
        </div>
    );
}

export default Footer;